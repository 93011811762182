import { createIcon } from '@chakra-ui/icons';

export const SympeeLogo = createIcon({
    displayName: 'SympeeLogo',
    viewBox: '0 0 159 36',
    path: (
        <svg width='159' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_779_2563)'>
                <path
                    d='M150.008 14.7119C147.75 14.7119 146.138 15.961 145.399 17.7692C145.247 18.1456 145.544 18.5301 145.95 18.5301H153.911C154.309 18.5301 154.607 18.1592 154.467 17.7856C153.78 15.9528 152.198 14.7147 150.008 14.7147V14.7119ZM150.008 11.791C155.092 11.791 158.378 15.5819 158.318 20.6874C158.316 21.0147 158.04 21.271 157.713 21.271H145.65C145.285 21.271 144.996 21.5847 145.067 21.9447C145.571 24.5301 147.567 25.9537 150.101 25.9537C151.923 25.9537 153.464 25.2365 154.298 23.8537C154.421 23.6492 154.637 23.5101 154.874 23.5101H157.097C157.525 23.5101 157.822 23.9328 157.645 24.3228C156.207 27.4347 153.292 28.9074 150.071 28.9074C145.091 28.9074 141.546 25.3292 141.546 20.3819C141.546 15.4347 145.064 11.7965 150.011 11.7965L150.008 11.791Z'
                    fill='#034D21'
                />
                <path
                    d='M132.292 14.7119C130.034 14.7119 128.422 15.961 127.683 17.7692C127.53 18.1456 127.827 18.5301 128.234 18.5301H136.195C136.593 18.5301 136.89 18.1592 136.751 17.7856C136.064 15.9528 134.482 14.7147 132.292 14.7147V14.7119ZM132.292 11.791C137.375 11.791 140.662 15.5819 140.602 20.6874C140.599 21.0147 140.324 21.271 139.996 21.271H127.934C127.568 21.271 127.279 21.5847 127.35 21.9447C127.855 24.5301 129.851 25.9537 132.385 25.9537C134.206 25.9537 135.747 25.2365 136.582 23.8537C136.705 23.6492 136.92 23.5101 137.157 23.5101H139.38C139.808 23.5101 140.105 23.9328 139.928 24.3228C138.491 27.4347 135.575 28.9074 132.355 28.9074C127.375 28.9074 123.829 25.3292 123.829 20.3819C123.829 15.4347 127.347 11.7965 132.295 11.7965L132.292 11.791Z'
                    fill='#034D21'
                />
                <path
                    d='M114.472 25.6837C117.303 25.6837 119.362 23.5074 119.362 20.3465C119.362 17.1856 117.336 15.0092 114.472 15.0092C111.608 15.0092 109.435 17.2156 109.435 20.3465C109.435 23.4774 111.611 25.6837 114.472 25.6837ZM115.216 11.791C119.509 11.791 122.76 15.3392 122.76 20.3465C122.76 25.3537 119.569 28.9019 115.186 28.9019C113.449 28.9019 111.985 28.3265 110.85 27.3801C110.376 26.9819 109.465 27.2901 109.465 27.9119V35.3792C109.465 35.7228 109.186 36.001 108.843 36.001H106.716C106.372 36.001 106.094 35.7228 106.094 35.3792V12.7701C106.094 12.4265 106.372 12.1483 106.716 12.1483H108.843C109.186 12.1483 109.465 12.4265 109.465 12.7701V12.8137C109.465 13.4356 110.384 13.7437 110.858 13.3401C111.993 12.3774 113.463 11.791 115.219 11.791H115.216Z'
                    fill='#034D21'
                />
                <path
                    d='M98.3973 11.791C101.855 11.791 103.942 14.1174 103.942 18.0228V27.9256C103.942 28.2692 103.664 28.5474 103.32 28.5474H101.193C100.849 28.5474 100.571 28.2692 100.571 27.9256V18.7374C100.571 16.2937 99.5864 15.0119 97.32 15.0119C94.6664 15.0119 93.2946 17.0683 93.2946 20.0492L93.3218 27.9228C93.3218 28.2665 93.0437 28.5474 92.7 28.5474H90.5455C90.2018 28.5474 89.9237 28.2692 89.9237 27.9256V18.7374C89.9237 16.2937 88.9391 15.0119 86.6728 15.0119C84.0191 15.0119 82.6773 17.0683 82.6773 20.0492V27.9256C82.6773 28.2692 82.3991 28.5474 82.0555 28.5474H79.9282C79.5846 28.5474 79.3064 28.2692 79.3064 27.9256V12.7701C79.3064 12.4265 79.5846 12.1483 79.9282 12.1483H82.1046C82.4209 12.1483 82.6746 12.4047 82.6746 12.7183C82.6746 13.2965 83.5282 13.5665 83.9591 13.1792C84.9491 12.2847 86.1928 11.791 87.7718 11.791C89.7518 11.791 91.2628 12.5547 92.2091 13.9619C92.5091 14.4092 93.2673 14.4583 93.6028 14.0356C94.7237 12.6256 96.3164 11.791 98.3864 11.791H98.3973Z'
                    fill='#034D21'
                />
                <path
                    d='M74.64 12.5174C74.7409 12.2938 74.9619 12.1492 75.2073 12.1492H77.4464C77.8991 12.1492 78.1991 12.6156 78.0109 13.0274L67.7237 35.6365C67.6228 35.8574 67.4018 35.9992 67.1591 35.9992H64.9146C64.4618 35.9992 64.1618 35.5328 64.35 35.121L68.16 26.7156C68.2337 26.5519 68.2337 26.3665 68.16 26.2028L62.2037 13.0247C62.0182 12.6128 62.3182 12.1465 62.7709 12.1465H65.01C65.2582 12.1465 65.4819 12.2938 65.58 12.5201L69.5046 21.5256C69.72 22.0219 70.4209 22.0247 70.6418 21.5283L74.6455 12.5147L74.64 12.5174Z'
                    fill='#034D21'
                />
                <path
                    d='M55.0283 28.9027C51.0083 28.9027 48.0437 27.0972 47.1465 23.9909C47.0401 23.62 47.3346 23.2682 47.7192 23.2682H49.9365C50.2146 23.2682 50.4546 23.4563 50.5583 23.7154C51.1583 25.2236 52.5001 25.9218 55.0883 25.9218C57.2646 25.9218 58.6065 25.2672 58.6065 24.0427C58.6065 22.6709 57.0574 22.4636 54.3137 21.8063C49.7237 20.6745 47.7846 19.75 47.7846 17.0063C47.7846 13.6982 50.5883 11.7891 54.701 11.7891C58.5492 11.7891 60.8483 13.4636 61.6665 16.0327C61.7865 16.4063 61.4892 16.7663 61.0965 16.7663H58.8928C58.631 16.7663 58.4019 16.5972 58.2792 16.3654C57.7065 15.2936 56.5065 14.77 54.641 14.77C52.4646 14.77 51.1828 15.5145 51.1828 16.6491C51.1828 17.6936 52.1974 17.9609 55.0583 18.6454C59.501 19.69 62.0646 20.4645 62.0646 23.6527C62.0646 27.1709 58.9037 28.9 55.0283 28.9V28.9027Z'
                    fill='#034D21'
                />
                <path
                    d='M9.81818 35.1816C9.81818 35.6344 9.45273 35.9998 9 35.9998H2.19273C0.981818 35.9998 0 35.018 0 33.8071V26.9998C0 26.5471 0.365455 26.1816 0.818182 26.1816C1.27091 26.1816 1.63636 26.5471 1.63636 26.9998V33.8071C1.63636 34.1153 1.88455 34.3635 2.19273 34.3635H9C9.45273 34.3635 9.81818 34.7289 9.81818 35.1816Z'
                    fill='#034D21'
                />
                <path
                    d='M36.0001 26.9998V33.8071C36.0001 35.018 35.0182 35.9998 33.8073 35.9998H27.0001C26.5473 35.9998 26.1819 35.6344 26.1819 35.1816C26.1819 34.7289 26.5473 34.3635 27.0001 34.3635H33.8073C34.1155 34.3635 34.3637 34.1153 34.3637 33.8071V26.9998C34.3637 26.5471 34.7292 26.1816 35.1819 26.1816C35.6346 26.1816 36.0001 26.5471 36.0001 26.9998Z'
                    fill='#FD6B00'
                />
                <path
                    d='M9.81818 0.818182C9.81818 1.27091 9.45273 1.63636 9 1.63636H2.19273C1.88455 1.63636 1.63636 1.88455 1.63636 2.19273V9C1.63636 9.45273 1.27091 9.81818 0.818182 9.81818C0.365455 9.81818 0 9.45273 0 9V2.19273C0 0.981818 0.981818 0 2.19273 0H9C9.45273 0 9.81818 0.365455 9.81818 0.818182Z'
                    fill='#034D21'
                />
                <path
                    d='M27.0001 0H33.8073C35.0182 0 36.0001 0.981818 36.0001 2.19273V9C36.0001 9.45273 35.6346 9.81818 35.1819 9.81818C34.7292 9.81818 34.3637 9.45273 34.3637 9V2.19273C34.3637 1.88455 34.1155 1.63636 33.8073 1.63636H27.0001C26.5473 1.63636 26.1819 1.27091 26.1819 0.818182C26.1819 0.365455 26.5473 0 27.0001 0Z'
                    fill='url(#paint0_linear_779_2563)'
                />
                <path
                    d='M15.4583 4.9082H6.28373C5.52282 4.9082 4.90918 5.52184 4.90918 6.28275V15.4573C4.90918 16.2182 5.52282 16.8318 6.28373 16.8318H15.4583C16.2192 16.8318 16.8328 16.2182 16.8328 15.4573V6.28275C16.8328 5.52184 16.2192 4.9082 15.4583 4.9082ZM15.1965 15.1955H6.54554V6.54457H15.1965V15.1955Z'
                    fill='#034D21'
                />
                <path
                    d='M12.0845 8.28125H9.65726C8.89908 8.28125 8.28271 8.89761 8.28271 9.6558V12.0831C8.28271 12.8413 8.89908 13.4576 9.65726 13.4576H12.0845C12.8427 13.4576 13.4591 12.8413 13.4591 12.0831V9.6558C13.4591 8.89761 12.8427 8.28125 12.0845 8.28125ZM11.8227 11.8212H9.91908V9.91761H11.8227V11.8212Z'
                    fill='#034D21'
                />
                <path
                    d='M29.6319 4.9082H20.4573C19.6964 4.9082 19.0828 5.52184 19.0828 6.28275V15.4573C19.0828 16.2182 19.6964 16.8318 20.4573 16.8318H29.6319C30.3928 16.8318 31.0064 16.2182 31.0064 15.4573V6.28275C31.0064 5.52184 30.3928 4.9082 29.6319 4.9082ZM29.37 15.1955H20.7191V6.54457H29.37V15.1955Z'
                    fill='url(#paint1_linear_779_2563)'
                />
                <path
                    d='M26.2581 8.28125H23.8308C23.0727 8.28125 22.4563 8.89761 22.4563 9.6558V12.0831C22.4563 12.8413 23.0727 13.4576 23.8308 13.4576H26.2581C27.0163 13.4576 27.6327 12.8413 27.6327 12.0831V9.6558C27.6327 8.89761 27.0163 8.28125 26.2581 8.28125ZM25.9963 11.8212H24.0927V9.91761H25.9963V11.8212Z'
                    fill='url(#paint2_linear_779_2563)'
                />
                <path
                    d='M15.4583 19.0586H6.28373C5.52282 19.0586 4.90918 19.6722 4.90918 20.4331V29.6077C4.90918 30.3686 5.52282 30.9822 6.28373 30.9822H15.4583C16.2192 30.9822 16.8328 30.3686 16.8328 29.6077V20.4331C16.8328 19.6722 16.2192 19.0586 15.4583 19.0586ZM15.1965 29.3459H6.54554V20.695H15.1965V29.3459Z'
                    fill='#034D21'
                />
                <path
                    d='M12.0845 22.4316H9.65726C8.89908 22.4316 8.28271 23.048 8.28271 23.8062V26.2335C8.28271 26.9916 8.89908 27.608 9.65726 27.608H12.0845C12.8427 27.608 13.4591 26.9916 13.4591 26.2335V23.8062C13.4591 23.048 12.8427 22.4316 12.0845 22.4316ZM11.8227 25.9716H9.91908V24.068H11.8227V25.9716Z'
                    fill='#034D21'
                />
                <path
                    d='M27.5455 19.7422C26.5691 19.7422 25.7591 20.2631 25.2546 20.6804C25.1809 20.7431 25.11 20.8031 25.0446 20.8631C24.9791 20.8031 24.9082 20.7431 24.8346 20.6804C24.33 20.2631 23.52 19.7422 22.5437 19.7422C20.7191 19.7422 19.2273 21.2095 19.2273 23.0367C19.2273 23.7076 19.3609 24.7822 20.0918 26.0504C20.82 27.3131 22.1155 28.7231 24.36 30.1031H24.3628C24.57 30.2313 24.8046 30.2967 25.0446 30.2967C25.2846 30.2967 25.5218 30.2313 25.7264 30.1058H25.7291C27.9764 28.7231 29.2691 27.3131 29.9973 26.0504C30.7282 24.7822 30.8618 23.7076 30.8618 23.0367C30.8618 21.2095 29.37 19.7422 27.5455 19.7422ZM28.5791 25.2349C28.0282 26.1895 26.9918 27.3731 25.0446 28.6031C23.0973 27.3731 22.0609 26.1895 21.51 25.2322C20.9455 24.2531 20.8637 23.4704 20.8637 23.0367C20.8637 22.1286 21.6082 21.3786 22.5437 21.3786C22.95 21.3786 23.3918 21.6104 23.79 21.9404C23.9782 22.0958 24.1337 22.254 24.24 22.374C24.2918 22.434 24.3327 22.4804 24.36 22.5131C24.3709 22.5295 24.3818 22.5404 24.3846 22.5458C24.3846 22.5486 24.39 22.5513 24.39 22.5513C24.5427 22.7586 24.7855 22.8786 25.0446 22.8786C25.3037 22.8786 25.5464 22.7586 25.6991 22.5513L25.7046 22.5458C25.7046 22.5458 25.7182 22.5295 25.7318 22.5131C25.7564 22.4804 25.7973 22.434 25.8491 22.374C25.9555 22.254 26.1109 22.0958 26.2991 21.9404C26.7 21.6104 27.1391 21.3786 27.5455 21.3786C28.4809 21.3786 29.2255 22.1286 29.2255 23.0367C29.2255 23.4704 29.1437 24.2531 28.5791 25.2349Z'
                    fill='url(#paint3_linear_779_2563)'
                />
            </g>
            <defs>
                <linearGradient id='paint0_linear_779_2563' x1='28.5055' y1='-0.177273' x2='36.4664' y2='6.31909' gradientUnits='userSpaceOnUse'>
                    <stop offset='0.55' stopColor='#4C5617' />
                    <stop offset='1' stopColor='#DC6704' />
                </linearGradient>
                <linearGradient id='paint1_linear_779_2563' x1='19.98' y1='6.63729' x2='30.2482' y2='15.2173' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#0E4E20' />
                    <stop offset='1' stopColor='#FC6B00' />
                </linearGradient>
                <linearGradient id='paint2_linear_779_2563' x1='22.7372' y1='9.8658' x2='26.4354' y2='11.4749' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#5F5815' />
                    <stop offset='1' stopColor='#F66A01' />
                </linearGradient>
                <linearGradient id='paint3_linear_779_2563' x1='20.2418' y1='20.4976' x2='29.0755' y2='26.7949' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#D25900' />
                    <stop offset='1' stopColor='#FA6A00' />
                </linearGradient>
                <clipPath id='clip0_779_2563'>
                    <rect width='158.318' height='36' fill='white' />
                </clipPath>
            </defs>
        </svg>
    ),
});
